<template>
  <base-material-card icon="mdi-view-dashboard" :title="$t('dashboard.admin')">
    <v-row>
      <v-col cols="6">
        <v-card raised elevation="6" class="ma-1">
          <v-row>
            <v-col cols="7" md="7" lg="8" class="pb-0">
              <v-card-title>{{$tc('studio')}}</v-card-title>
            </v-col>
            <v-col cols="5" md="5" lg="4" class="pb-0">
              <v-select dense :items="studioTattooerItems" outlined v-model="tabstudios" append-icon="mdi-chevron-down"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-card-text>
              <v-tabs-items v-model="tabstudios">
                <v-tab-item value="1">
                  <studio-report />
                </v-tab-item>
                <v-tab-item value="2">
                  <studio-graph />
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card raised elevation="6" class="ma-1">
          <v-row>
            <v-col cols="7" md="7" lg="8" class="pb-0">
              <v-card-title>{{$tc('tattooer')}}</v-card-title>
            </v-col>
            <v-col cols="5" md="5" lg="4" class="pb-0">
              <v-select dense :items="studioTattooerItems" outlined v-model="tabtattooer" append-icon="mdi-chevron-down"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-card-text>
              <v-tabs-items v-model="tabtattooer">
                <v-tab-item value="1">
                  <tattooer-report />
                </v-tab-item>
                <v-tab-item value="2">
                  <tattooer-graph />
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card raised elevation="6" class="ma-1">
          <v-card-title>{{$t('appointments.income')}}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-tabs dark v-model="tabmonthlytattooer">
              <v-tab href="#1">{{$t('reports.income_total')}}</v-tab>
              <v-tab href="#2">{{$t('reports.monthly_income')}}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabmonthlytattooer">
              <v-tab-item value="1">
                <tattooer-monthly-graph />
              </v-tab-item>
              <v-tab-item value="2">
                <tattooer-monthly-ind-graph />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card raised elevation="6" class="my-1">
          <v-card-title>{{$tc('appointment',2)}}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-tabs dark v-model="tabmonthlyappointments">
              <v-tab href="#1">{{$t('reports.appointments_total')}}</v-tab>
              <v-tab href="#2">{{$t('reports.monthly_appointments')}}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabmonthlyappointments">
              <v-tab-item value="1">
                <appointment-monthly-graph />
              </v-tab-item>
              <v-tab-item value="2">
                <appointment-monthly-ind-graph />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
export default {
  name: "AdminDashboard",
  data() {
    return {
      tabstudios: '1',
      tabtattooer: '1',
      tabmonthlytattooer: null,
      tabmonthlyappointments: null,
      studioTattooerItems: [
        {
          text: this.$t('table'),
          value: '1',
        },
        {
          text: this.$t('graph'),
          value: '2',
        },
      ],
    };
  },
  components: {
    StudioReport: () => import("@/components/reports/admin/StudioReport"),
    TattooerReport: () => import("@/components/reports/admin/TattooerReport"),
    StudioGraph: () => import("@/components/reports/admin/StudioGraph"),
    TattooerGraph: () => import("@/components/reports/admin/TattooerGraph"),
    TattooerMonthlyGraph: () =>
      import("@/components/reports/admin/TattooerMonthlyGraph"),
    TattooerMonthlyIndGraph: () =>
      import("@/components/reports/admin/TattooerMonthlyIndGraph"),
    AppointmentMonthlyGraph: () =>
      import("@/components/reports/admin/AppointmentMonthlyGraph"),
    AppointmentMonthlyIndGraph: () =>
      import("@/components/reports/admin/AppointmentMonthlyIndGraph"),
  },
};
</script>

<style lang="sass" scoped>
.v-select
  padding-right: 8px !important

.v-card__text
  padding-top: 0px !important

</style>